import "./Menu.scss"
import { useNavigate } from 'react-router-dom';
export function Menu() {
  const navigate = useNavigate();

  const goToLeadershipPage = () => {
    navigate('/our-leadership');
  };


  const goToMainPage = () => {
    navigate('/');
  };

  const goToOurBeliefs = () => {
    navigate('/what-we-believe');
  };

  const goToContact = () => {
    navigate('/contact');
  };

  return (
    <div id="menu">
      <div className="menu-item" onClick={e => { goToMainPage() }}>Home</div>
      <div className="menu-item" onClick={e => { goToOurBeliefs() }}>What we believe</div>
      <div className="menu-item" onClick={e => { goToLeadershipPage() }}>Our leadership</div>
      <div className="menu-item" onClick={e => { goToContact() }}>Have a question for us?</div>
    </div>

  );
}