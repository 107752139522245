import MenuIcon from '@mui/icons-material/Menu';
import logo from "../img_resources/ebc_logo.png";
import { Menu } from "./Menu"
import { useEffect, useState } from "react";
export function Topbar({ propShowFullMenu }) {
  const [showFullMenu, setShowFullMenu] = useState(false);

  useEffect(() => {
    propShowFullMenu(showFullMenu);

  }, [showFullMenu]);
  return (
    <div className="topbar">
      <img src={logo} className="logo" />
      <div className="menu" onClick={e => { setShowFullMenu(!showFullMenu) }}><MenuIcon /></div>
    </div>)

}