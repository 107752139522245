import { Navbar } from "./Navbar"
import "./Contact.scss";
import facebookLogo from "../img_resources/facebook.png";
export function Contact() {
  return (<>
    <div>
      <Navbar />
      <div className="contact">
        Please contact one of our leaders Sia Thang (954)258-5871 or Sia Khai Pu (409)554-5121 if you have any questions. <br />We look forward to meeting you!
      </div>

      <div className="facebook">
        <a target="_blank" href="https://www.facebook.com/ebcbgky/"><img className="facebook-logo" src={facebookLogo} /></a>

      </div>
    </div></>);
}