import "./MainPage.scss"
import { useNavigate } from 'react-router-dom';
import { Navbar } from "./Navbar.js"
import { MapContainer } from "./GoogleMaps"
export function MainPage() {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="main-page">
        <div className="title">
          Evangelical<br />Baptist<br />Church
      </div>
        <div className="location">
          Bowling Green, KY
      </div>
        <div className="call-to-action" onClick={e => { navigate('/contact'); }}>
          Connect with us!
      </div>
        {/* <Button size="large" variant="outlined" color="success">Contained</Button> */}
        <div className="info">
          <div className="title">Services:</div>
          Sunday 11:00AM<br />
          Saturday 6:30PM
        <div className="title">Location:</div>
          Western Gateway Shopping Center.<br />
          1901 Russellville Rd,<br />
          Bowling Green, KY 42101
          <MapContainer />
        </div>
      </div>
    </>

  );
}