import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from 'react-router-dom';
import { useState } from "react"

import { Believe } from "./components/Believe.js";
import { MainPage } from "./components/MainPage.js";
import { Leadership } from "./components/Leadership.js";
import { Contact } from "./components/Contact.js";

function App() {
  const [page, setPage] = useState("home");

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/what-we-believe" element={<Believe />} />
        <Route path="/our-leadership" element={<Leadership />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
