import { React, useState } from "react"
import { Navbar } from "./Navbar"
import "./Leadership.scss";
import facebookLogo from "../img_resources/facebook.png";
import siaKhaiPu from "../img_resources/sia_khai_pu.jpeg";
import siaThang from "../img_resources/sia_thang.jpeg";
import siaKhai from "../img_resources/sia_khai.jpeg";
import siaSangPi from "../img_resources/sia_sang_pi.jpeg";

export function Leadership() {
  return (
    <>
      <Navbar />
      <div className="leadership">
        <div className="title">
          <h1>Our leadership</h1>
          {/* <a target="_blank" href="https://www.facebook.com/ebcbgky/"><img className="facebook-logo" src={facebookLogo} /></a> */}
        </div>
        <div className="pastors">
          <div className="profile">
            <h3>Sia Thang (Lead Pastor)</h3>
            <img className="pastor-photo" src={siaThang} />
          </div>
          <div className="profile">
            <h3>Sia Khai Pu (Youth Pastor)</h3>
            <img className="pastor-photo" src={siaKhaiPu} />
          </div>
        </div>
      </div>
    </>
  )
}