import { Navbar } from "./Navbar"
import "./Believe.scss"
export function Believe() {
  return (<>
    <Navbar />
    <>
      <div className="our-beliefs">
        <h2>UPNA PULAKNA</h2>
        <p>EBC pawlpi sungah a nuai-a upna pulaknate in up ding le zuih ding hi, ci-in khauhtakin kisang hi.</p>
        <ol>
          <h3><li>Lai Siangtho (The Scriptures)</li></h3>
          <p>Lai Siangtho Bu 66 a kigelh cilna-ah a buppi-in, Pasian’ humop hi-a, khialhna khat beek kihel lo hi. Lai Siangtho kicing zo-a, thuneihna khempeuh nei hi. Gamtatna, upna, ngaihsutna khempeuh sittel nading THUBU hi, ci-in i um hi. (2 Tim. 3:16; 2 Pet. 1:21)</p>
          <h3><li>A Maan Pasian (The True God)</li></h3>
          <p>Pa Pasian, Ta Pasian, Kha Siangtho Pasian thum gawm a nungta Pasian maan khat bek om hi. Tua thumte in, panmun tuamtek a neih uh hangin, kipumkhatin sem khawm uh hi, ci-in i um hi. (Exo. 20:2,3; 1 Cor. 8:6; Rev. 4:11).</p>
          <h3><li>Kha Siangtho (The Holy Spirit)</li></h3>
          <p>Kha Siangtho in mimal Pasian hi-a, Pa Pasian le Ta Pasian tawh kizakimin, a pianngei uh kibang hi. Van le lei piansakna-ah kihel kim uh hi. Thu umlo hih leitungah Pasian’ geelsa nate a tangtun masiah Dawi Mangpa kamkaih kang hi. Mawhna, dikna, thukhennate phawksakin, lungdamnathu hilhna le tecipanna-ah, mite ciamteh, taangsapna guan, lamlak, hilh, tecipang, siangthosak, huh hi, ci-in i um hi. (John 1:33; 3:5,6, 14:16, 17, 26; 16:8-11; Matt. 28:19, Heb. 9:14; Luke 1:35; Gen. 1:1-3; Acts 5:30-32; Eph. 1:13, 14; Mark 1:8; Acts 11:16; Luke 24:49; Rom. 8:14; 26, 27).</p>
          <h3><li>Dawi Mangpa (The Devil or Satan)</li></h3>
          <p>Dawi Mangpa pen mimal-in om takpi-a, Pasian in vantung mi khat banga a piansak hi napi kiphatsakna le lehdona hang tawh Pasian’ galpa suak hi. Hih leitung khuamial ukpa le dawi gilo suak ahih manin, Meilipi sungah thukhenna a thuak ding pa hi, ci-in i um hi. (Matt. 4:1-11; 2 Cor. 4:4; Rev. 20:10).</p>
          <h3><li>Piansakna (Creation)</li></h3>
          <p>Van le lei le a piang na khempeuh ama thu-a piang tawm mawk hi lo-in, Piancil Lai Siangtho sungah a kigelh bang tektek a, Pasian mah in a piansak ahi hi. Mihing zong ama thu-a piang tawm hilo-in, Pasian in a piansak hi-a, leitung mihing khempeuh a kipiangsak masa Adam le Eve suan hi, ci-in i um hi. (Gen. 1,2; Col. 1:16, 17; John. 1:3).</p>
          <h3><li>Mihing Pukna (The Fall of Man)</li></h3>
          <p>A piangsak Pa in mawhnei lo-in, mihing a piansak ahih hangin, Adam in ama teeltawm tawh mawhna a bawl manin, a dinmun masa panin puk hi. Mi khempeuh in, Adam pianngei luahsuk uh ahih manin pianngei mawhna le gamtat mawhna tawh mimawh suak uh hi. Mihingte Pasian’ mawhsakna panin, a kihu thei, a peng thei om lo hi, ci-in, i um hi. (Gen. 3:16; Rom. 3:3, 10-19; 5:12, 19, 1:18, 32).</p>
          <h3><li>Paubanglo Suahna (The Virgin Birth)</li></h3>
          <p>Zeisu paubanna om lo nungak siangtho Mary sung panin, Kha Siangtho in suaksak hi. Tua bang tumdang suahna tawh mihing ta khat beek suak ngei lo a, zong suak ngei lo ding hi. Tua Zeisu in Pasian Tapa lah hi-a, Tapa Pasian lah hi, ci-in i um hi. (Gen. 3:15; Isa. 7:14; Matt. 1:18-25; Luke 1:35; John 1:14).</p>
          <h3><li>Hotkhiatna (Salvation)</li></h3>
          <p>Pasian Tapa palaina tungtawnin hehpihna hang bek tawh mawhna sung pan hotkhiatna lampi Pasian in hong phuangkhia hi. Pa Pasian sehna bangin, mawhnei lo Tapa Pasian in Pa Pasian thukham mangin, eite taang dingin mawhlawh hong tawisak a, sihna hong thuak hi. I mawhnate Ama pumpi tungah pua-in, eite taangin singkhuam tungah hong si hi. Tua Topa Zeisu Khazih upna bek tawh hotkhiatna kingah hi, ci-in i um hi. (John 1:29; Eph. 2:8; Acts 15:11; Rom. 3:24, 25; John 3:16; Matt. 18:11; Phil. 2:7, 8; Heb. 2:14-17; Isa. 53:4-7; 1 John 4:10; 1 Cor. 15:3; 2 Cor. 5:21; 1 Pet. 2:24).</p>
          <h3><li>Khazih Thawhkikna le Siampi Suahna (The Resurrection and Priesthood of Christ)</li></h3>
          <p>Khazih Topa a pumpi mahmah tawh thokik a, van kahin, Pasian ziat lamah Siampi-in tu a, eite aa dingin hong thuumsak hi, ci-in i um hi. (Matt. 28:6, 7; Luke 24:39; John 20:27; 1 Cor. 15:4; Mark 16:6; Luke 24:2-6, 51; Acts 1:9-11; Rev. 3:21; Heb. 8:6; 12:2; 7:25; 1 Tim. 2:5; 1 John 2:1; Heb. 2:17; 5:9, 10).</p>
          <h3><li>Hehpihna le Pianthakna (Grace and The New Birth)</li></h3>
          <p>Mawhnei mite in hotkhiatna a ngah theih nadingin, pianthak kul hi. Pianthakna in Zeisu Khazih sunga mi thak suahna hi-a, damdama piangthu hi lo-in, thakhat thu-a piang ahi hi. Mawhna le khialhna sunga si ngeisa mi khat a pianthak ciangin, Pasian pianzia le Pasian letsong nuntak tawntungna ngah pah hi. Pianthakna pen Pasian thuman thutak dungzui-in Kha Siangtho nasepna hang bek tawh mite in amau teeltawmna tungtawna Lungdamna Thu a san theihna uh ahi hi. Hih pen mite tel zawh loh Pasian nasepna ahi hi. A siangtho kisikna gah ahi upna le nuntakna thakte panin pianthakna hong kilangkhia hi, ci-in i um hi. (John 3:3; 2 Cor. 5:17; 1 John 5:1; Acts 16:20-33; 2 Pet. 1:4; Rom. 6:23; Eph. 2:1, 5; Col. 2:13; John 3:8).</p>
          <h3><li>Diktansakna (Justification)</li></h3>
          <p>Diktansakna pen Pasian in thukhenmang thuneihna tawh Zeisu Khazih a um mite “Dik” tang hi ci-a hong ciaptehna ahi hi. Diktangte in Pasian mawh maisakna le Pasian dikna mahmah ngah pah uh hi. Diktansakna pen eima nasepna pha hang hi lo-in, hong Tanpa sisan hang beka kingah hi, ci-in i um hi. (Rom. 3:24; 4:5; 5:1, 9; Gal. 2:16; Phil. 3:9).</p>
          <h3><li>Sianthosakna (Sanctification)</li></h3>
          <p>Sianthosakna in, thu-um mite Pasian in a tuam koihna ahi hi. Siangthosakna dawl thum pha hi. A dawl masa pen, Khazih hong tatna ahi Zeisu Khazih Honpa le Topa-a san phetin a kingah sianthosakna ahi hi. A dawl nihna pen Kha Siangtho in Pasian Kammal zanga, nisim nuntakna-ah, thu-um mite a sianthosak tohtohna ahi hi. A dawl thumna pen a bucing sianthosakna hi-a, Topa hong paikik ciangin kicing pan bek ding hi, ci-in i um hi. (Heb. 10:10-14; 3:1; John 17:17; 2 Cor. 3:18; 1 Cor. 1:30; Eph. 5:25-27; 1 Thes. 4:3,4; 5:23-24; 1 John 3:2; Jude 24, 25; Rev. 22:11).</p>
          <h3><li>Gupna Taankik Lohna (Security of the Saints)</li></h3>
          <p>A piangthak takpite Zeisu Khazih adingin, Pasian in a tawntungin bittakin kem hi, ci-in i um hi. (Phil. 1:6; John 10:28, 29; Rom. 8:35-39, Jude 1).</p>
          <h3><li>Tuiphumna le Nekkhawmna (Baptism and the Lord’s Supper)</li></h3>
          <p>Tuiphumna pen si-in, kivui-a, a thokik i Honpa tawh pum khat suahkhopna limlaakna ahih manin tui sungah phum tektek kul hi, ci-in i um hi. (Acts 8:36, 38, 39; John 3:23; Rom. 6:3-5; Matt. 3:16; Col. 2:12; 1 Cor. 11:23-28; Matt. 28:18-20; Acts 2:41-42).
        Nekkhawmna in Topa sihna, a hong paikik mateng phawkna hi-a, a piangthak tui kiphumsa mite in amah le amah a kiteldot khit ciangin nek ding ahi hi, ci-in i um hi. (1 Cor. 11:23-28; Acts 2:41-42).</p>
          <h3><li>Pawlpi (The Church)</li></h3>
          <p>Pawlpi nam nih om-a, muh theih pawlpi le muh theih loh pawlpi ahi hi. Leitungah upna le pianzia kibang teng in mun khata Pasian a biakkhopna pen i muh theih pawlpi hi-a, Zeisu Khazih sungah a piangthak khempeuh pum khat ihihna pen muh theihloh pawlpi hi, ci-in i um hi. (Acts 5:11; 8:1; 9:31; 14:32; 15:4; 16:4; 22:41; 1 Tim. 3:14, 15; Matt. 16:18; Eph. 1:23; 3:6; Gal. 1:6).</p>
          <h3><li>Leitung Kumpite (Civil Government)</li></h3>
          <p>Gammite phattuamna bawl dingin Pasian in kumpite koih ahih mah bangin Zeisu’ deihna tawh kilehbulh thute simlohah kumpite le thunei uliante zahtakin, a thu uh mangin, thungetsak ding hi, ci-in i um hi. (Rom. 13:17; 1 Sam. 23:3; Exo. 18:21, 22; Acts 5:29; 4:19; 20; 23:5; Matt. 22:21; Dan. 3:17, 18).</p>
          <h3><li> Laktohna le A Nungzui-a Piang ding Thute (Rapture and Subsequent Events)</li></h3>
          <p>Mi Siangthote la dingin, kum tul maanna ma-in, Khazih hong pai ding hi. Tua hun ding kithei lo-a, tuhun khat peuhpeuh hi thei hi. Tua hun ciangin Khazih sunga a sisa mite minthang pumpi tawh thokik ding uh hi. A nungta a omlaite sihna ciam lo-in, minthang pumpi kipia dinga, a vekpi un Topa dawn dingin mei tungah laktohna ngah ding uh hi, ci-in i um hi. (1 Thes. 4:13-18; 1 Cor. 15:41-44, 51-54; Phil. 3:20, 21; Rev 3:10).<br /><br />
          Laktohna khit ciangin, leitungah kum sagih sung gimna lianpi om ding a, tua zomin David kumpipa tokhom tungah tu-a, kum tul mang dingin vangliatna le minthangna tawh Topa hong kilang ding hi, ci-in i um hi. (Dan. 9:25-27; Matt. 24:28-31; Luke 1:31-33; Isa. 9:6, 7; 11:1-9; Acts 2:29, 30; Rev. 20:1-4, 6)</p>
          <h3><li>Vantung Gam (Heaven)</li></h3>
          <p>Vantung gam pen a thu bek-a om hi lo-in, tu mahmahin a taktak-a a om gige ahi hi. Ni tawpni ciangin, hong kilang dinga, van thak le lei thak zong om ding hi, ci-in i um hi. (Isa. 65:17; 66:22; John 14:1-3; 2 Pet. 3:13; Rev. 21:1; Luke 23:3; 2 Cor. 12:4, 5, 12: Rev. 2:7).</p>
          <h3><li>Hell le Meilipi (Hell and Lake of Fire)</li></h3>
          <p>Hell pen a thu bekin a om hi lo-in, tu mahmahin a taktak-a om gige ahi hi. Ni tawpni ciangin Satan le a pawlte a kikhiat nading Meilipi zong om hi, ci-in i um hi. (Matt. 18:9; Mark 9:47; Luke 16:23; Rev. 19:20; 20:10, 14, 15).</p>
          <h3><li>Midikte le Misiate (The Righteous and the Wicked)</li></h3>
          <p>Midikte le misiate kilamdang sitset hi. Topa Zeisu Khazih hanga, diktansakna ngah a, Kha Siangtho in a sianthosakte midik takpi-in Pasian in sang hi. A kisikkik lo thu-um lo mite Pasian mit muhah hamsiatna thuak misiate ahi hi. Hih kilamdanna pen leitung nuntak sung bek hi lo-in, sih khit ciangin zong kilamdang zawsem ding hi. Midikte in nuntak tawntungna ngahin misiate in ahih leh Meilipi sungah phawk gegasa mahin tawntung gimna thuak ding hi, ci-in i um hi. (Mal. 3:18; Gen. 18:23; Rom. 6:17, 18: 1 John. 5:19; Rom. 7:6; 6:23; Prov. 14:32; Luke 16:15; Matt. 25:34-41; John. 8:21; Rev. 20:14,15).</p>
          <h3><li>Tuhun Christian-te kalsuanzia tuamtuam tawh kisai EBC dinmun (EBC’ Stand on Contemporary Christian Movement)</li></h3>
          <p>Tuhun Christian kalsuanzia pen a huampi-in nam thumin kikhen thei hi.
          <br /><br />
          A Khatna: Upna peng ahi “Liberalism or Modernism or Neo Orthodoxy” hi. Hih upna peng in Lai Siangtho buppi Pasian’ Kammal cih sang lo hi. Lai Siangtho sungah a kikhial tampi om hi ci hi. Upna le zuihna-ah Lai Siangtho kia kicing zo lo hi ci-in um ahih manin sinna le telkhehna thupi ngaihsut lo uh hi. Lai Siangtho pen mihingte gelh laibu dangte tawh kikimin sang hi. Kha thu le vantung thu sangin ci le sa le leitung nuntakna thupisak zaw hi. Lai Siangtho a gelh mite le a kigelh hun zong man lo hi ci hi. A tomin ci leng upna peng in Christian upna laigil teng paaikhia ahih manin EBC in sang lo hi.
          <br /><br />
          A Nihna: Lai Siangtho bek a bulphuh upna laigil ahi “Fundamentalism” or “Evangelicalism” ahi hi. Hih upna laigil in Lai Siangtho buppi ahi bangin sanga, paihkhiat le behlap nei lo hi. Lai Siangtho buppi in Pasian’ Kammal ahih manin khialhna khat beek om kilkel lo hi ci-in um hi. Lai Sianghtho bek mah Christiante adingin upna le zuihna le siksan ding ahih manin Lai Siangtho theihcian kul hi ci-in um a, Lai Siangtho sinna le telkhehna thupi sak hi. Lai Siangtho sungah, Zeisu Khazih Hotkhiatna a thupi ahihna tawh kizui-in, hih upna laigil in hotkhiatna thu bulphuhin kalsuan ahih manin EBC in kisang hi.
          <br /><br />
          AThumna:Limsang upna kici “Pentecostalism” or “Charismaticism” or “The Third Wave Movement” ahi hi. Hih limsang upna in Lai Siangtho bek Pasian’ Kammal hi lo hi. Pasian’ Kammal omzia lahna bek hi. Lai Siangtho pualam le Lai Siangtho banah Pasian’ Kammal om lai hi ci-in um hi. Tua ahih manin limsang upna sungah kamsanna, thusuak, kamtuam pauna, kha sungah laamna, nuihna, kahna, tukna le Psalm late, etc., um bek tham lo-in bulphuh mahmah hi. Lai Siangtho hilhna tawh kituak lo “Hauzo Damzo” upna sang hi. Lai Siangtho sangin thusuak tuamtuamte bulphuh zaw ahih manin Lai Siangtho sinna le telkhehna thupisak lo hi. Lai Siangtho bulphuh lo ahih manin a hunhunin upna khelkhel, upna thakthak piangtawm den hi.
        </p>
        </ol>
Hih upna le kalsuanzia nam thumte lakah EBC  dinmunin Lai Siangtho a bulphuh upna laigil “Evangelicalism” ahi hi. Upna peng (Liberalism or Modernism or Neo-Orthodoxy) le limsang upna (Pentecostalism or Chrismaticism or The Thid Wave Movement) te EBC in sang lo hi.
      <br /><br />
(Reference: EBC THULET BULPI, ZATTAWM NGEINA, PANSIAN UPADI)
    </div>
    </>
  </>)
}