import { Menu } from "./Menu.js"
import { Topbar } from "./Topbar.js";
import { useState } from "react"
import "./Navbar.scss"
export function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  function propShowFullMenu(status) {
    return setShowMenu(status)
  }
  return (
    <div className="app navbar">
      <Topbar propShowFullMenu={propShowFullMenu} />
      <div className={showMenu ? "fade-in" : ""}>
        {showMenu && <Menu />}
      </div>
    </div>
  )
}