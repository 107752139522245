import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
const apiKey = process.env.REACT_APP_API_KEY;
export function MapContainer() {
  const location = { lat: 36.97958499713112, lng: -86.47033408042418 };
  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={{ height: '400px', width: '100%' }}
        zoom={18} // Adjust the zoom level as needed
        center={location}
      >
        <Marker position={location} />
      </GoogleMap>
    </LoadScript>
  );
};


